import PaymentInfluencerInvoiceView from "presentation/Admin/InfluencerInvoice/PaymentInvoiceView"
import MicroResourceView from "presentation/Student/Course/MicroResourceView" //imported microresource
import MicroResourceShortsView from "presentation/Student/Course/MicroResourceShortsView"
import NotFound from "core/components/404"
import BatchAssessmentView from "presentation/Admin/AllClasses/BatchAssignment/BatchAssessmentView"
import DetailedRatingView from "presentation/Admin/AllClasses/DetailedRatingView"
import GetAllClassesView from "presentation/Admin/AllClasses/AllClassesView"
import SubmissionView from "presentation/Admin/AllClasses/SubmissionView/SubmissionView"
import BatchProgressRoadmapView from "presentation/Admin/BatchDashboard/BatchProgressRoadmapView"
import CoinsTracker from "presentation/Admin/CoinsTracker/CoinsTrackerView"
import TransactionView from "presentation/Admin/CoinsTracker/TransactionView"
import CompanyView from "presentation/Admin/Company/CompanyView"
import AdminContestDuplicateView from "presentation/Admin/Contest/ContestDuplicateView"
import AdminContestEditView from "presentation/Admin/Contest/ContestEditView"
import AdminContestSubmissionView from "presentation/Admin/Contest/ContestSubmissionView"
import AdminContestView from "presentation/Admin/Contest/ContestView"
import AdminStudentSubmissionView from "presentation/Admin/Contest/SubmissionView/SubmissionView"
import ContestApproveView from "presentation/Admin/ContestApprove/ContestApproveView"
import ContestProctoringView from "presentation/Admin/ContestProctoring/ContestProctoringView"
import CreateBatchView from "presentation/Admin/CreateBatch/CreateBatchView"
import CreateClassView from "presentation/Admin/CreateClass/CreateClassView"
import CreateOnboardingManagerView from "presentation/Admin/CreateOnboardingManger/CreateOnboardingManagerView"
import DocumentationFileAddView from "presentation/Admin/Documentation/DocumentationFileAddView"
import DocumentationView from "presentation/Admin/Documentation/DocumentationView"
import EditBatchView from "presentation/Admin/EditBatch/EditBatchView"
import EndBatchView from "presentation/Admin/EndBatch/EndBatchView"
import AdminHomeView from "presentation/Admin/Home/HomeView"
import GenerateInvoiceView from "presentation/Admin/Invoice/GenerateInvoiceView"
import InstructorView from "presentation/Admin/Invoice/InstructorView"
import MonthSessionsView from "presentation/Admin/Invoice/MonthSessionsView"
import InvoicingMainPage from "presentation/Admin/Invoicing/InvoicingMainPageView"
import CreateJob from "presentation/Admin/JobsDashboard/CreateJobView"
import EligibleCandidateView from "presentation/Admin/JobsDashboard/EligibleCandidateView"
import JobDashboardView from "presentation/Admin/JobsDashboard/JobDashboardView"
import AdminMcqEditView from "presentation/Admin/Mcqs/McqEditView"
import AdminMcqsView from "presentation/Admin/Mcqs/McqsView"
import MentorDashboardView from "presentation/Admin/MentorDashboard/MentorDashboardView"
import MentorProfilePageView from "presentation/Admin/MentorDashboard/MentorProfilePageView"
import GenerateMentorInvoiceView from "presentation/Admin/MentorInvoicing/GenerateMentorInvoiceView"
import MentorDetailsView from "presentation/Admin/MentorInvoicing/MentorDetailsView"
import AdminMonthlySessionsView from "presentation/Admin/MentorInvoicing/MonthlySessionsView"
import MentorSessionsView from "presentation/Admin/MentorSessions/MentorSessionsView"
import MentorsView from "presentation/Admin/MentorSessions/MentorsView"
import MentorshipEditView from "presentation/Admin/Mentorship/MentorshipEditView"
import MentorshipStudentEditView from "presentation/Admin/Mentorship/MentorshipStudentEditView"
import MentorshipView from "presentation/Admin/Mentorship/MentorshipView"
import FeedbackTable from "presentation/Admin/MonthlyFeedback/FeedbackTable"
import PaymentInvoiceView from "presentation/Admin/PaymentInvoice/PaymentInvoiceView"
import AddPlacementForm from "presentation/Admin/Placement/AddPlacementForm"
import PlacementViewAdmin from "presentation/Admin/Placement/PlacementView"
import CreatePlacementBatchView from "presentation/Admin/PlacementCreateBatch/CreateBatchView"
import PlacementDashboardView from "presentation/Admin/PlacementDashboard/DashboardView"
import EditStudentProfile from "presentation/Admin/PlacementDashboard/EditStudentProfileView"
import CreateNewProjectView from "presentation/Admin/ProjectSection/CreateNewProjectView"
import CreateProjectSectionView from "presentation/Admin/ProjectSection/CreateProjectsSectionView"
import EditProjectView from "presentation/Admin/ProjectSection/EditProjectView"
import SubCaseQuestionView from "presentation/Admin/QuestionChamber/Questions/SubCaseQuestionView"
import QuestionsChamberView from "presentation/Admin/QuestionChamber/View"
import QuestionView from "presentation/Admin/Questions/Question/View"
import QuestionsView from "presentation/Admin/Questions/View"
import AdminRedeemCoinsView from "presentation/Admin/RedeemCoins/StoreView"
import SheetsView from "presentation/Admin/Sheets/SheetsView"
import AdminStoreView from "presentation/Admin/Store/StoreView"
import CCAvenue from "presentation/Admin/StudentDashboard/BreakDowns/CCAvenue/CCAvenueBreakdownView"
import PaymentBreakdownView from "presentation/Admin/StudentDashboard/BreakDowns/Razorpay/PaymentBreakdownView"
import ProgressRoadmapView from "presentation/Admin/StudentDashboard/ProgressRoadmap/ProgressRoadmapView"
import ResetPasswordView from "presentation/Admin/StudentDashboard/ResetPassword/View"
import StudentDashboardView from "presentation/Admin/StudentDashboard/StudentDashboardView"
import StudentProfileView from "presentation/Admin/StudentDashboard/StudentProfileView"
import TestDashboardView from "presentation/Admin/TestDashboard/TestDashboardView"
import UpdateBatchView from "presentation/Admin/UpdateBatch/UpdateBatchView"
import UpdateWalkThroughView from "presentation/Admin/UpdateWalkThrough/UpdateWalkThroughView"
import UserSignupView from "presentation/Admin/UserSignup/UserSignupView"
import ScheduledClassesView from "presentation/Admin/ViewScheduledClasses/ScheduledClassesView"
import ComponentView from "presentation/ComponentView"
import AllMentorSessionView from "presentation/Mentor/AllSession/AllSessionView"
import DoubtSupportViewNew from "presentation/Student/DoubtSupport/DoubtSupportViewNew"
import AvailabilityView from "presentation/Mentor/Availability/AvailabilityView"
import GoalSettingAvailabilityView from "presentation/Mentor/Availability/GoalSettingAvailabilityView"
import MentorHomeView from "presentation/Mentor/Home/HomeView"
import LiveClassView from "presentation/Mentor/LiveClass/LiveClassView"
import MonthlySessionsView from "presentation/Mentor/MonthlySessions/MonthlySessionsView"
import MenteeDetailsPage from "presentation/Mentor/MyMentees/MenteeDetailsPageView"
import MyMenteesView from "presentation/Mentor/MyMentees/MyMenteesView"
import AssessmentView from "presentation/Student/Assessment/ContestView"
import CaseStudyView from "presentation/Student/Assignments/CaseStudy/View"
import MCQView from "presentation/Student/Assignments/Mcq/View"
import SubjectiveView from "presentation/Student/Assignments/Subjective/View"
import CourseSessionView from "presentation/Student/Course/CourseSessionView"
import CourseView from "presentation/Student/Course/CourseView"
import QuestionBankView from "presentation/Student/Course/QuestionBankView"
import DoubtSupportView from "presentation/Student/DoubtSupport/DoubtSupportView"
import EditorView from "presentation/Student/Editor/EditorView"
import HomeAssessmentView from "presentation/Student/Home/Assessment/View"
import HomeVideoSection from "presentation/Student/Home/components/HomeVideoSection"
import HomeView from "presentation/Student/Home/NewView"
import LeaderboardView from "presentation/Student/Leaderboard/LeaderboardView"
import LoginView from "presentation/Student/Login/LoginView"
import MastersProgramView from "presentation/Student/MastersProgram/MastersProgramView"
import MentorView from "presentation/Student/Mentor/MentorView"
import PaymentMethodsView from "presentation/Student/Payment/PaymentMethodsView"
import PlacementView from "presentation/Student/Placement/PlacementView"
import ProfileEditView from "presentation/Student/Profile/ProfileEditView"
import ResumeCreateView from "presentation/Student/Profile/ResumeCreateView"
import ResumeView from "presentation/Student/Profile/ResumeView"
import ProgressManagerView from "presentation/Student/ProgressManager/ProgressManagerView"
import OrderHistoryRedeemCoins from "presentation/Student/RedeemCoins/components/OrderHistory"
import RedeemCoinsView from "presentation/Student/RedeemCoins/StoreView"
import ScheduleView from "presentation/Student/Schedule/ScheduleView"
import OrderHistory from "presentation/Student/Store/components/OrderHistory"
import StoreView from "presentation/Student/Store/StoreView"
import ContestEditorView from "presentation/User/Contest/Editor/EditorView"
import ContestStart from "presentation/User/Contest/Start/View"
import ContestView from "presentation/User/Contest/View"
import PaymentOptionsView from "presentation/Student/PaymentOptions/PaymentOptionsView"
import ConfirmOrder from "presentation/Student/Store/components/ConfirmOrder"
import ReferEarnView from "presentation/Student/ReferandEarn/ReferEarnView"
import ReferLinkView from "presentation/Student/ReferandEarn/ReferLinkView"
import ReferandEarnView from "presentation/Admin/ReferandEarn/ReferandEarnView"
import OnboardingView from "presentation/Student/Onboarding/OnboardingView"
import CommunityAnnouncementView from "presentation/Admin/CommunityAnnouncement/CommunityAnnouncementView"
import AIFeedbackView from "presentation/Admin/ViewAIFeedback/AIFeedbackView"
import LoginResetView from "presentation/Student/Login/LoginResetView"
import ForgotPasswordView from "presentation/Student/Login/ForgotPasswordView"
import InvoicingView from "presentation/Mentor/Invoicing/InvoicingView"
import InfluencerInvoicingView from "presentation/Influencer/Invoice/InvoicingView"
import InvoiceSessionDataView from "presentation/Mentor/Invoicing/InvoicingSession/InvoiceSessionDataView"
import NewMentorView from "presentation/Student/Mentor/NewMentorView"
import ContestFlowView from "presentation/Admin/ContestFlow/ContestFlowView"
import EditContestFlowView from "presentation/Admin/ContestFlow/EditContestFlowView"
import CreateContestFlowView from "presentation/Admin/ContestFlow/CreateContestFlowView"
import ContestQuestionwiseSubmissionView from "presentation/Student/Assessment/ContestQuestionwiseSubmissionView"
import InfluencerDashboardView from "presentation/Admin/InfluencerDashboard/InfluencerDashboardView"
import InfluencerProfilePageViewModel from "presentation/Admin/InfluencerDashboard/InfluencerProfilePageViewModel"
import StudentProgressView from "presentation/Student/StudentProgress/StudentProgressView"
import FeatureFeedbackTable from "presentation/Admin/FeatureFeedback/FeatureFeedbackTable"
import ClassFeedbackView from "presentation/Student/Course/ClassFeedbackView"
import DeveloperHomeView from "presentation/Developer/Home/HomeView"
import DoubtSupportScheduleView from "presentation/Admin/DoubtSupportSchedule/DoubtSupportScheduleView"
import SuperAdminDashboardView from "presentation/SuperAdmin/SuperAdminDashboard/SuperAdminDashboardView"
import AdminLoginView from "presentation/Admin/Login/AdminLoginView"
import SuperAdminLoggerDashboardView from "presentation/SuperAdmin/SuperAdminLoggerDashboard/SuperAdminLoggerDashboardView"
import InstructorAvailabilityView from "presentation/Mentor/InstructorAvailability/InstructorAvailabilityView"
import InstructorAvailabilityDashboardView from "presentation/Admin/InstructorAvailabilityDashboard/InstructorAvailabilityDashboardView"
import InfluencerCompensation from "presentation/Influencer/Compensation/CompensationView"
import InfluencerHomeView from "presentation/Influencer/Home/HomeView"
import InfluencerProfilePageView from "presentation/Admin/InfluencerDashboard/InfluencerProfilePageView"
import CertificateView from "presentation/Admin/Certficate/CertificateView"

export interface RouteConfig {
  path: string
  component: () => JSX.Element
  isProtected?: boolean
}

const routes: RouteConfig[] = [
  { path: "/login", component: LoginView, isProtected: false },
  { path: "/admin/login", component: AdminLoginView, isProtected: false },
  { path: "/forgot_password", component: ForgotPasswordView, isProtected: false },

  { path: "/home", component: HomeView },
  { path: "/home/contest", component: HomeAssessmentView },
  {
    path: "/home/meet_and_greet/:id",
    component: () => HomeVideoSection({ type: "meet_and_greet" }),
  },
  {
    path: "/home/prerequisite/:id",
    component: () => HomeVideoSection({ type: "prerequisite" }),
  },
  { path: "/onboarding", component: OnboardingView },
  { path: "/placement", component: PlacementView },
  { path: "/placement/edit", component: ProfileEditView },
  { path: "/placement/edit/resume", component: ResumeView },
  { path: "/placement/edit/resume/create", component: ResumeCreateView },
  { path: "/course", component: CourseView },
  { path: "/microresource", component: MicroResourceView },
  { path: "/microresourceshorts", component: MicroResourceShortsView },
  { path: "/course/:session_id", component: CourseSessionView },
  { path: "/question-bank", component: QuestionBankView },
  { path: "/payment", component: PaymentMethodsView },
  { path: "/doubt-support", component: DoubtSupportView },
  { path: "/mentor", component: NewMentorView },
  { path: "/schedule", component: ScheduleView },
  { path: "/student-progress", component: StudentProgressView },
  { path: "/progress-meeting", component: ProgressManagerView },
  { path: "/weekly-test-series", component: AssessmentView },
  { path: "/leaderboard", component: LeaderboardView },
  { path: "/store", component: StoreView },
  { path: "/store/:item_id", component: ConfirmOrder },
  { path: "/store/history", component: OrderHistory },
  { path: "/redeem-coins", component: RedeemCoinsView },
  { path: "/refer", component: ReferEarnView },
  { path: "/referlink", component: ReferLinkView, isProtected: false },
  { path: "/class-feedback/:id", component: ClassFeedbackView, isProtected: false },
  { path: "/referlink/:email", component: ReferLinkView, isProtected: false },
  { path: "/transaction", component: PaymentOptionsView },
  { path: "/masters-program", component: MastersProgramView },
  { path: "/contests", component: ContestView },
  { path: "/contests/:batch_id/:contest_id", component: ContestStart },
  { path: "/contests/:batch_id/mock/:contest_id", component: ContestStart },
  { path: "/contests/:batch_id/:contest_id/editor", component: ContestEditorView },
  { path: "/contests/:batch_id/mock/:contest_id/editor", component: ContestEditorView },
  { path: "/editor/:id", component: EditorView },
  { path: "/mcqs/:id", component: MCQView },
  { path: "/subjective/:id", component: SubjectiveView },
  { path: "/casestudy/:id", component: CaseStudyView },
  { path: "/contests/:contestId/:emailId/:questionId/:type", component: ContestQuestionwiseSubmissionView },
  { path: "/contests/:contestId/rec/:emailId/:questionId/:type", component: ContestQuestionwiseSubmissionView },
  { path: "/password_reset", component: LoginResetView },

  // SUPER ADMIN
  { path: "/admin/super/dashboard", component: SuperAdminDashboardView },
  { path: "/admin/super/logs", component: SuperAdminLoggerDashboardView },
  // ADMIN
  { path: "/admin/home", component: AdminHomeView },
  { path: "/admin/end-batch", component: EndBatchView },
  { path: "/admin/create-class", component: CreateClassView },
  { path: "/admin/project-section", component: CreateProjectSectionView },
  { path: "/admin/project-section/create-new-project", component: CreateNewProjectView },
  { path: "/admin/project-section/edit-project/:projectId", component: EditProjectView },
  { path: "/admin/create-batch", component: CreateBatchView },
  { path: "/admin/create-onboarding-manager", component: CreateOnboardingManagerView },
  { path: "/admin/batch-progress-roadmap", component: BatchProgressRoadmapView },
  { path: "/admin/edit-batch", component: EditBatchView },
  { path: "/admin/user-signup", component: UserSignupView },
  { path: "/admin/referandearn", component: ReferandEarnView },
  { path: "/admin/community-announcements", component: CommunityAnnouncementView },
  { path: "/admin/placement", component: PlacementViewAdmin },
  { path: "/admin/placement-dashboard", component: PlacementDashboardView },
  { path: "/admin/placement-dashboard/edit/:id", component: EditStudentProfile },
  { path: "/admin/create-placement-batch", component: CreatePlacementBatchView },
  { path: "/admin/placement/edit/:id", component: AddPlacementForm },
  { path: "/admin/placement/add", component: AddPlacementForm },
  { path: "/admin/students", component: StudentDashboardView },
  { path: "/admin/students/:id", component: StudentProfileView },
  { path: "/admin/students/:id/payment-breakdown", component: PaymentBreakdownView },
  { path: "/admin/students/:id/ccavenue-payment-breakdown", component: CCAvenue },
  { path: "/admin/students/:id/progress-roadmap", component: ProgressRoadmapView },
  { path: "/admin/students/:id/reset-password", component: ResetPasswordView },
  { path: "/admin/mentor-dashboard", component: MentorDashboardView },
  { path: "/admin/mentor-profile/edit/:id", component: MentorProfilePageView },
  { path: "/admin/certificate", component: CertificateView },
  { path: "/admin/influencer/profile/edit/:id", component: InfluencerProfilePageView },
  { path: "/admin/update-batch", component: UpdateBatchView },
  { path: "/admin/questions", component: QuestionsView },
  { path: "/admin/questions/:id", component: QuestionView },
  { path: "/admin/question-chamber", component: QuestionsChamberView },
  { path: "/admin/question-chamber/:id", component: SubCaseQuestionView },
  { path: "/admin/question-chamber/:op/:id", component: SubCaseQuestionView },
  { path: "/admin/scheduled-classes", component: ScheduledClassesView },
  { path: "/admin/job-dashboard", component: JobDashboardView },
  { path: "/admin/job-create-job", component: CreateJob },
  { path: "/admin/job-eligible-candidates/:id", component: EligibleCandidateView },
  { path: "/admin/test-dashboard", component: TestDashboardView },
  { path: "/admin/mentorship", component: MentorshipView },
  { path: "/admin/mentorship/:id", component: MentorshipStudentEditView },
  { path: "/admin/mentorship/:id/:type", component: MentorshipEditView },
  { path: "/admin/redeem-coins", component: AdminRedeemCoinsView },
  { path: "/admin/coins-tracker", component: CoinsTracker },
  { path: "/admin/coins-tracker/transaction-history/:email", component: TransactionView },
  { path: "/admin/proctoring", component: ContestProctoringView },
  { path: "/admin/store", component: AdminStoreView },
  { path: "/admin/sheets", component: SheetsView },
  { path: "/admin/contests", component: AdminContestView },
  { path: "/admin/contests/:id/view", component: AdminContestSubmissionView },
  { path: "/admin/contests/:id/view/:id", component: AdminStudentSubmissionView },
  { path: "/admin/contests/:id", component: AdminContestEditView },
  { path: "/admin/contests/:id/duplicate", component: AdminContestDuplicateView },
  { path: "/admin/approval-requests", component: ContestApproveView },
  { path: "/admin/mcqs", component: AdminMcqsView },
  { path: "/admin/mcqs/:id", component: AdminMcqEditView },
  { path: "/admin/documentation_files", component: DocumentationView },
  { path: "/admin/documentation_files/:id", component: DocumentationFileAddView },
  { path: "/admin/all-feedback", component: FeedbackTable },
  { path: "/admin/feature-feedback", component: FeatureFeedbackTable },
  { path: "/admin/all-classes", component: GetAllClassesView },
  { path: "/admin/all-classes/:id", component: DetailedRatingView },
  { path: "/admin/all-classes/:id/submission", component: BatchAssessmentView },
  { path: "/admin/all-classes/:id/submission/:email_id", component: SubmissionView },
  { path: "/admin/generate-invoice", component: GenerateInvoiceView },
  { path: "/admin/generate-invoice/:id", component: InstructorView },
  { path: "/admin/generate-invoice/:id/:yearMonth", component: MonthSessionsView },
  { path: "/admin/update-walk-through", component: UpdateWalkThroughView },
  { path: "/admin/invoicing", component: InvoicingMainPage },
  { path: "/admin/instructor-availability", component: InstructorAvailabilityDashboardView },
  { path: "/admin/mentor-sessions", component: MentorsView },
  { path: "/admin/mentor-sessions/:id", component: MentorSessionsView },
  { path: "/admin/mentor-invoice", component: GenerateMentorInvoiceView },
  { path: "/admin/mentor-invoice/:id", component: MentorDetailsView },
  { path: "/admin/mentor-invoice/:id/:yearMonth", component: AdminMonthlySessionsView },
  { path: "/admin/components", component: ComponentView },
  { path: "/admin/company-tags", component: CompanyView },
  { path: "/admin/ai-feedback", component: AIFeedbackView },
  { path: "/admin/doubt-support-schedule", component: DoubtSupportScheduleView },
  { path: "/admin/influencer/invoicing", component: PaymentInfluencerInvoiceView },
  { path: "/admin/influencer/dashboard", component: InfluencerDashboardView },
  { path: "/admin/invoice", component: PaymentInvoiceView },

  //EXAM TEMPLATE
  { path: "/admin/exam-flow", component: ContestFlowView },
  { path: "/admin/exam-flow/create", component: CreateContestFlowView },
  { path: "/admin/exam-flow/:id/edit", component: EditContestFlowView },

  { path: "/mentor/home", component: MentorHomeView },
  { path: "/mentor/sessions", component: AllMentorSessionView },
  { path: "/mentor/live-class", component: LiveClassView },
  { path: "/mentor/mentees", component: MyMenteesView },
  { path: "/mentor/mentees/:id", component: MenteeDetailsPage },
  { path: "/mentor/monthly-sessions", component: MonthlySessionsView },
  { path: "/mentor/availability", component: AvailabilityView },
  { path: "/mentor/goal-setting-availability", component: GoalSettingAvailabilityView },
  { path: "/mentor/instructor-availability", component: InstructorAvailabilityView },
  { path: "/mentor/invoicing/:page", component: InvoicingView },
  { path: "/mentor/invoicing-sessions/:role/:month", component: () => InvoiceSessionDataView({}) },

  // INFLUENCER
  { path: "/influencer/home", component: InfluencerHomeView },
  { path: "/influencer/invoice", component: InfluencerInvoicingView },
  { path: "/influencer/compensation", component: InfluencerCompensation },

  // DEVELOPER
  { path: "/developer/home", component: DeveloperHomeView },

  // 404
  { path: "*", component: NotFound },
]

export default routes
