import AwardEmpty from "assets/svgs/AwardEmpty"
import DottedCircleLoder from "assets/svgs/DottedCircleLoder"
import { Button } from "core/components/v2/Button"
import StandardSelect from "core/components/v2/StandardSelect"
import { AwardIcon, CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { useState } from "react"

const MeetingWaitingModal = ({
  isOpen,
  onClose,
  waitingTime,
  handleNewSubmitFeedback,
  onMenteeAbsent,
  onTakenElseWhere,
}: any) => {
  if (!isOpen) return null

  const [rating, setRating] = useState([
    {
      title: "Confidence",
      star: 0,
    },
    {
      title: "Naming Convention",
      star: 0,
    },
    {
      title: "Reaction to Hints",
      star: 0,
    },
    {
      title: "Time Complexity Analysis",
      star: 0,
    },
    {
      title: "Thinking Process",
      star: 0,
    },
    {
      title: "Implementation Speed",
      star: 0,
    },
    {
      title: "Explanation Clarity",
      star: 0,
    },
    {
      title: "Overall Rating",
      star: 0,
    },
  ])

  const [status, setStatus] = useState("")
  const [newComment, setNewComment] = useState("")

  const handleClick = (index: number, i: number) => {
    const newRating = rating
    newRating[i].star = index + 1

    setRating([...newRating])
  }
  return (
    <div className="relative z-40 ">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0 "></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 flex min-h-screen items-center justify-center">
        <div className="relative bg-white w-[450px] h-[535px] overflow-y-auto rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="p-[24px]">
            <div className="flex justify-between">
              <p className="text-[#333] text-[16px]">Meeting Lobby</p>
              <button
                className={cn("hover:bg-slate-200 rounded-[4px]", isOpen?.edit ? "block" : "hidden")}
                onClick={onClose}
              >
                <CrossIcon className="size-[24px]" />
              </button>
            </div>

            <p className="text-[#646464] text-[14px] font-[500]">Mentor Session</p>

            <div className="my-[16px]">
              <p className="text-[#646464] text-[14px] font-[500]">{isOpen?.name}</p>
              <p className="text-[#646464] text-[14px] font-[500]">
                Batch: {isOpen?.batch} | {isOpen?.phone}
              </p>
            </div>

            <StandardSelect
              dimensions="w-[300px]"
              className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              placeholder={"Select here"}
              name="status"
              value={status}
              onChange={(value: string) => {
                setStatus(value)
              }}
              options={isOpen?.id ? ["Session Conducted", "Mentee Absent", "Taken Elsewhere"] : ["Session Conducted"]}
              required
              disabled={waitingTime > 0}
            />

            {!status && !isOpen?.edit && (
              <>
                <div className="flex my-[16px] gap-[8px] items-center">
                  <DottedCircleLoder />
                  <div className="rounded-[4px] bg-[#F5F5F5] px-[6px] py-[4px]">
                    <span className="text-[#DC2626] text-[14px] font-[600]">
                      {waitingTime > 0 ? (
                        <>
                          {Math.floor(waitingTime / 60)}:{Math.floor(waitingTime % 60)} Min
                        </>
                      ) : (
                        <>00:00 Min</>
                      )}
                    </span>
                  </div>
                </div>
                <p className="text-[#333] text-[14px] font-[400]">Note ** You can select status after 15 mins.</p>
              </>
            )}

            {(status === "Session Conducted" || status === "Taken Elsewhere") && (
              <div className="my-[16px] text-[#333]">
                <p className="text-[14px] font-[600]">
                  Mentor Feedback For Student <span className="text-[#DC2626]">*</span>
                </p>
                <p className="text-[14px] font-[400]">Rate between 1 to 5 (with 1 = poor; 5= excellent)</p>

                <div>
                  {rating.map((item: any, i: number) => (
                    <div className="flex justify-between my-[16px] items-center">
                      <li>{item.title}</li>

                      <div className="flex gap-[8px] mt-[8px]">
                        {Array.from({ length: 5 }, (_, index) => (
                          <div key={index} onClick={() => handleClick(index, i)} className="cursor-pointer">
                            {index < item.star ? (
                              <AwardIcon className="size-[16px]" />
                            ) : (
                              <AwardEmpty className="size-[16px]" />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                  <p className="text-[14px] font-[600]">Custom Feedback</p>
                  <textarea
                    onChange={(e: any) => setNewComment(e.target.value)}
                    value={newComment}
                    className="my-1 h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
                    placeholder="Write your feedback..."
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className={cn(
              "bottom-0 flex justify-end w-full h-[59px] bg-[#F9F9F9] px-[24px] py-[12px]",
              status === "Session Conducted" || status === "Taken Elsewhere" ? "sticky" : "absolute"
            )}
          >
            <Button
              onClick={() => {
                if (status === "Session Conducted") {
                  handleNewSubmitFeedback(isOpen?.room_id, rating, newComment)
                  onClose()
                }
                if (status === "Mentee Absent") {
                  onMenteeAbsent()
                }
                if (status === "Taken Elsewhere") {
                  handleNewSubmitFeedback(isOpen?.room_id, rating, newComment)
                  onTakenElseWhere()
                }
              }}
              size={"sm"}
              variant={"cancel"}
              className="px-[16px] py-[9px]"
              border={"thin"}
              disabled={status !== "Mentee Absent" && !rating.every((rate: any) => rate.star !== 0)}
            >
              End Session
              {/* {loading ? <Spinner small={true} /> : "Submit"} */}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetingWaitingModal
