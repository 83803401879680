import React, { useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import Button from "core/components/new/Button"
import { Mentor } from "domain/model/Mentor"
import { UpdateMentor } from "domain/useCase/Student/Mentor/UpdateMentor"
import { format, set } from "date-fns"
import { GetAllMentors } from "domain/useCase/Student/Mentor/GetAllMentors"
import { GetAllTempMentors } from "domain/useCase/Student/Mentor/GetAllTempMentors"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import { STR_FAILURE, STR_MENTOR_ALLOTED_STATUS, STR_PAID, STR_SUCCESS } from "core/constants/strings"
import { useNavigate } from "react-router-dom"
import { GetMentorAvailability } from "domain/useCase/Student/Mentor/GetMentorAvailability"
import { GetBookedSessions } from "domain/useCase/Student/Mentor/GetBookedSessions"
import { GetAllMentorSessions } from "domain/useCase/Student/Mentor/GetAllMentorSessions"
import { AddSessionReview } from "domain/useCase/Student/Mentor/AddSessionReview"
import { BookMentorSession } from "domain/useCase/Student/Mentor/BookMentorSession"
import { CancelMentorSession } from "domain/useCase/Student/Mentor/CancelMentorSession"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import Menu from "assets/svgs/Menu"
import { styled } from "@mui/material/styles"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { is } from "date-fns/locale"
import { isEmpty } from "core/utils/misc"
import { GetMentorSessionLinkStudent } from "domain/useCase/Student/Mentor/GetMentorSessionLinkStudent"
import { MarkMentorAbsent } from "domain/useCase/Student/Mentor/MarkMentorAbsent"
import { GetMentorDetailsByStudent } from "domain/useCase/Student/Mentor/GetMentorDetailsByStudent"
import { MenuOpen } from "@mui/icons-material"
import { GetMentorSessionDetails } from "domain/useCase/Student/Mentor/GetMentorSessionDetails"
import { GetMentorNameFromId } from "domain/useCase/Admin/NewMentorSessions/GetMentorNameFromId"
import { NewMentorSessionsRepositoryImpl } from "data/repository/Admin/NewMentorSessionsRepositoryImpl"
import NewMentorSessionsAPIDataSourceImpl from "data/API/Admin/NewMentorSessionsAPIDataSourceImpl"
import { GetMentorJoin } from "domain/useCase/Student/Mentor/GetMentorJoin"
import { MarkTakenElsewhere } from "domain/useCase/Student/Mentor/MarkTakenElsewhere"
import useFeatureFeedbackViewModel from "../FeatureFeedback/FeatureFeedbackViewModel"
import { FreezeMentorSlot } from "domain/useCase/Student/Mentor/FreezeMentorSlot"
import { GetFreezeSlotDetails } from "domain/useCase/Student/Mentor/GetFreezeSlotDetails"
import { DateTime } from "luxon"

export default function MentorViewModel() {
  const { fetchLastFeedback, getJoiningDate, saveFeedback, shouldShowFeedbackAfter2Months } =
    useFeatureFeedbackViewModel()
  const navigate = useNavigate()
  const { auth, user } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { student } = useApp()
  const [allotedMentor, setAllotedMentor] = useLocalStorage<any>("alloted-mentor", {})
  const [isBookingInProgress, setIsBookingInProgress] = React.useState(false)

  const [mentorAvailability, setMentorAvailability] = useLocalStorage<any>("mentor-availability", [])
  const [showTempMentor, setShowTempMentor] = useState<boolean>(false)
  const [tempMentorAvailability, setTempMentorAvailability] = useLocalStorage<any>("mentor-availability", [])
  const [mentorSessions, setMentorSessions] = useLocalStorage<any>("mentor-sessions", {})
  const [mentorsList, setMentorsList] = useLocalStorage<any>("mentors-list", [])
  const [tempMentorsList, setTempMentorsList] = useState<any>([])

  const [isMentorAlloted, setIsMentorAlloted] = React.useState(() => !!allotedMentor)
  const [isBookingAvailable, setIsBookingAvailable] = React.useState(false)
  const [index, setIndex] = React.useState<any>("")
  const [mentor, setMentor] = React.useState<any>(() => allotedMentor)
  const [milestoneSessionMentorId, setMilestoneSessionMentorId] = React.useState<any>(student?.milestoneSessionMentorId)
  const [mentorDetails, setMentorDetails] = React.useState([])
  const [bookingDetails, setBookingDetails] = React.useState<any>(null)
  const [milestoneSessionMentor, setMilestoneSessionMentor] = React.useState<any>(null)
  const [tableData, setTableData] = React.useState<any>({
    header: [],
    rows: [],
  })
  const [mentorsListLocally, setMentorsListLocally] = useLocalStorage<any>("mentors-list", [])
  const [tableDataNew, setTableDataNew] = React.useState<any>({
    header: [],
    rows: [],
  })
  const [isMilestoneSession, setIsMilestoneSession] = React.useState<boolean>(false)
  const [isAllotmentStatusLoading, setIsAllotmentStatusLoading] = React.useState(false)
  const [feedbackLoading, setFeedbackLoading] = React.useState(false)
  const [isMentorSessionBooked, setIsMentorSessionBooked] = React.useState(false)
  const [realMentor, setRealMentor] = React.useState("")
  const [reviewIndex, setReviewIndex] = React.useState<number>(-1)
  const [bookingIndex, setBookingIndex] = React.useState<number>(-1)
  const [cancelSessionIndex, setCancelSessionIndex] = React.useState<number>(-1)
  const [rescheduleSessionIndex, setRescheduleSessionIndex] = React.useState<number>(-1)
  const [confirmSessionIndex, setConfirmSessionIndex] = React.useState<number>(-1)
  const [rating, setRating] = React.useState<number>(0)
  const [comment, setComment] = React.useState<string>("")
  const [limitReached, setLimitReached] = React.useState<boolean>(false)
  const [isMenuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [mentorSessionData, setMentorSessionData] = React.useState<any>([])
  const [newMentorSessionData, setNewMentorSessionData] = React.useState<any>([])
  const [menuActiveRow, setMenuActiveRow] = React.useState<number>(-1)
  const [newMentorshipFlow, setNewMentorshipFlow] = React.useState<boolean>(false)
  const [isRescheduleEnabled, setIsRescheduleEnabled] = React.useState<boolean>(false)
  const [meetLink, setMeetLink] = React.useState("")
  const [isAlloting, setIsAlloting] = React.useState(false)
  const [isSelectionStarted, setIsSelectionStarted] = React.useState<any>(false)
  const [feedbackIndex, setFeedbackIndex] = React.useState<number>(-1)
  const [allMentors, setAllMentors] = React.useState<any>(() => mentorsListLocally)
  const [cancellationInProgress, setCancellationInProgress] = React.useState(false)
  const [indexToBeMarkedAbsent, setIndexToBeMarkedAbsent] = React.useState("")
  const [sessId, setSessId] = React.useState("")
  const [isAvailabilityLoading, setIsAvailabilityLoading] = React.useState(false)
  const [allSelectedMentors, setAllSelectedMentors] = React.useState<any>([])
  const [isPremiumPopupVisible, setIsPremiumPopupVisible] = React.useState(false)
  const [showMentorPage, setShowMentorPage] = React.useState<boolean>(false)
  const [disableMentorCard, setDisableMentorCard] = React.useState<any>(false)
  const [sessionMentor, setSessionMentor] = React.useState<any>(false)
  const [msid, setMsid] = React.useState<any>(1)
  const [isTableLoading, setIsTableLoading] = React.useState(false)
  const [isBookedSessionCalled, setIsBookedSessionCalled] = React.useState(false)
  const [cancelReason, setCancelReason] = useState("")
  const [markSessionCompletedPopup, setMarkSessionCompletedPopup] = React.useState(false)
  const [freezeData, setFreezeData] = React.useState("")

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#414141",
      color: "#FFFFFF",
      boxShadow: theme.shadows[1],
      fontSize: "10px",
      maxWidth: "184px",
      textWrap: "wrap",
      padding: "8px 8px 8px 8px",
    },
  }))
  const BATCHES_TO_IGNORE_MENTOR_ALLOTMENT = ["B8", "B9", "B10A", "B10B", "BCA_Recorded"]
  const BATCHES_TO_IGNORE_NMF = ["NLDSA B24", "BCA-Recorded"]

  const getAllMentorsUseCase = new GetAllMentors(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getAllTempMentorsUseCase = new GetAllTempMentors(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))

  const getMentorDetailsByStudentUseCase = new GetMentorDetailsByStudent(
    new MentorRepositoryImpl(new MentorAPIDataSourceImpl())
  )

  const getMentorAvailabilityUseCase = new GetMentorAvailability(
    new MentorRepositoryImpl(new MentorAPIDataSourceImpl())
  )

  const getBookedSessionsUseCase = new GetBookedSessions(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getAllMentorSessionUseCase = new GetAllMentorSessions(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const addStudentReviewUseCase = new AddSessionReview(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getMentorSessionDetails = new GetMentorSessionDetails(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getMentorJoinUseCase = new GetMentorJoin(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const bookMentorSessionUseCase = new BookMentorSession(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const cancelMentorSessionUseCase = new CancelMentorSession(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const updateMentorUseCase = new UpdateMentor(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getMentorSessionLinkStudentUseCase = new GetMentorSessionLinkStudent(
    new MentorRepositoryImpl(new MentorAPIDataSourceImpl())
  )
  const markMentorAbsentUseCase = new MarkMentorAbsent(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getMentorNameFromIdUseCase = new GetMentorNameFromId(
    new NewMentorSessionsRepositoryImpl(new NewMentorSessionsAPIDataSourceImpl())
  )
  const markTakenElsewhereUseCase = new MarkTakenElsewhere(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const freezeMentorSlotUseCase = new FreezeMentorSlot(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))
  const getFreezeSlotUseCase = new GetFreezeSlotDetails(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))

  const [isOpen, setIsOpen] = React.useState<any>(false)

  function formatDate(inputDate: any) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    if (!dateRegex.test(inputDate)) return inputDate

    const dateObject = new Date(inputDate)

    const dayOfMonth = dateObject.getDate()
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    const dayWithOrdinal = getOrdinal(dayOfMonth)

    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }
  function getOrdinal(n: any) {
    const suffixes = ["th", "st", "nd", "rd"]
    const v = n % 100
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
  }

  async function fetchAllMentors() {
    handleMentorSessionsTable(mentorSessions)
    setIsAllotmentStatusLoading(!allotedMentor)

    const response = await getAllMentorsUseCase.invoke(auth, isMilestoneSession)
    setMentorDetails(response?.data?.mentor[0])
    setIsAllotmentStatusLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    const mentors = response?.data?.mentor
    setMentorsList(response?.data?.mentor)
    setAllMentors(response?.data?.mentor)
    const milestoneSessionMentor: any = mentors.find((m: any) => m.email === student?.milestone_session_mentor_id)
    setMilestoneSessionMentor(milestoneSessionMentor)
    let isMentorAlloted = response?.data?.message === STR_MENTOR_ALLOTED_STATUS
    setIsMentorAlloted(isMentorAlloted)
    if (isMentorAlloted) {
      setAllotedMentor(response?.data?.mentor[0])
    }
  }
  async function fetchAllTempMentors() {
    setIsAllotmentStatusLoading(true)

    const response = await getAllTempMentorsUseCase.invoke(auth)

    setIsAllotmentStatusLoading(false)

    setTempMentorsList(response?.data?.mentor)
  }

  async function markTakenElsewhere(data: any) {
    const response = await markTakenElsewhereUseCase.invoke(auth, data)
    window.location.reload()
  }

  async function freezeMentorSlot(data: any) {
    const response = await freezeMentorSlotUseCase.invoke(auth, data)
  }

  async function getFreezeSlotDetails(data: any) {
    const response = await getFreezeSlotUseCase.invoke(auth, data)
    setFreezeData(response?.data)
  }
  function convertToLocalTime(item: any) {
    const startTime = DateTime.fromSeconds(item.start_timestamp).toLocal()
    const endTime = DateTime.fromSeconds(item.end_timestamp).toLocal()

    const formatTime = (dt: any) => {
      const hours = dt.hour
      const minutes = dt.minute
      return minutes === 0 ? `${hours}.0` : `${hours + minutes / 60}`
    }

    return {
      date: startTime.toISODate(),
      start: formatTime(startTime), // e.g., "7.5" for 7:30
      end: formatTime(endTime),
      start_timestamp: item.start_timestamp,
      end_timestamp: item.end_timestamp,
    }
  }
  async function fetchMentorAvailability(isMilestoneSession: boolean, data: any) {
    setIsAvailabilityLoading(true)
    if (
      (!data || data === "") &&
      student?.batch_start_date >= 20240701 &&
      !BATCHES_TO_IGNORE_NMF.includes(student?.batch)
    ) {
      setMentorAvailability([])
      setIsAvailabilityLoading(false)
      return
    }
    setMentorAvailability([])
    const response = await getMentorAvailabilityUseCase.invoke(auth, isMilestoneSession, data, "")
    if (!response?.success) {
      setMentorAvailability([])
      if (response?.error === "Your mentor sessions limit has been reached") {
        setLimitReached(true)
      }
      setIsAvailabilityLoading(false)
      return
    }
    if (!response?.data?.length && !isMilestoneSession) {
      setShowTempMentor(true)
    } else {
      setShowTempMentor(false)
    }
    const convertedData = response?.data.map(convertToLocalTime)
    setMentorAvailability(convertedData)
    setIsAvailabilityLoading(false)
  }

  async function fetchTempMentorAvailability(isMilestoneSession: boolean, data: any, mentor_id: any) {
    setIsAvailabilityLoading(true)
    if (
      (!data || data === "") &&
      student?.batch_start_date >= 20240701 &&
      !BATCHES_TO_IGNORE_NMF.includes(student?.batch)
    ) {
      setMentorAvailability([])
      setIsAvailabilityLoading(false)
      return
    }
    setMentorAvailability([])
    const response = await getMentorAvailabilityUseCase.invoke(auth, isMilestoneSession, data, mentor_id)
    if (!response?.success) {
      setMentorAvailability([])
      if (response?.error === "Your mentor sessions limit has been reached") {
        setLimitReached(true)
      }
      setIsAvailabilityLoading(false)
      return
    }
    setMentorAvailability(response?.data)
    setIsAvailabilityLoading(false)
    return response?.data
  }

  async function getMentorJoinDuration(data: any) {
    const response = await getMentorJoinUseCase.invoke(auth, data)

    if (response?.success) {
      return response?.data
    } else {
      return 0
    }
  }

  async function getMentorDetailsByStudent(data: any) {
    const response = await getMentorDetailsByStudentUseCase.invoke(auth, data)

    setRealMentor(response?.data)
  }
  const [mentorId, setMentorId] = React.useState<any>([])

  async function getMentorNameFromId(data: any) {
    const response = await getMentorNameFromIdUseCase.invoke(auth, data)

    setMentorId(response?.data)
    setRealMentor(response?.data)
  }
  async function getSessionJoiningLink() {
    const response = await getMentorSessionLinkStudentUseCase.invoke(auth)
    if (response?.success) {
      setMeetLink(response.data)
    }
  }

  function handleMentorSelection(index: number) {
    if (allSelectedMentors.includes(index)) {
      removeMentor(index)
      return
    }
    if (allSelectedMentors.length > 2) alert("You can select maximum 3 mentor at a time.")
    else addMentor(index)
  }
  async function handleMarkMentorAbsent(data: any) {
    const response = await markMentorAbsentUseCase.invoke(auth, data)
    if (response?.success) {
      await fetchAllMentorSessions()
      changeToastDetails(STR_SUCCESS, "Mentor marked absent successfully")
      changeToastVisibility(true)
    } else {
      changeToastDetails(STR_FAILURE, "Error occured! Please retry after sometime")
      changeToastVisibility(true)
    }
  }
  async function handleMentorAllotment() {
    if (allSelectedMentors.length < 3) {
      changeToastDetails("failure", "Please select 3 mentors.")
      changeToastVisibility(true)

      return
    }

    if (user?.payment_status !== STR_PAID) {
      changeToastDetails("failure", "Please complete your payment")
      changeToastVisibility(true)

      setIsPremiumPopupVisible(true)
      return
    }
    let selectedMentorsEmailList = allMentors
      ?.map((mentor: Mentor) => mentor.email)
      ?.filter((_: any, i: number) => allSelectedMentors.includes(i))

    setIsAlloting(true)

    const response = await updateMentorUseCase.invoke(auth, selectedMentorsEmailList)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    setIsSelectionStarted(false)
    setIsMentorAlloted(true)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Mentors alloted successfully.")
    setIsAlloting(false)
    fetchAllMentors()
  }

  function handleClosePopup() {
    setIsPremiumPopupVisible(false)
  }

  function startSelectionProcess() {
    setIsSelectionStarted(true)
    fetchAllMentors()
  }

  function addMentor(index: number) {
    setAllSelectedMentors((allSelectedMentors: any) => [...allSelectedMentors, index])
  }

  function removeMentor(index: number) {
    setAllSelectedMentors((allSelectedMentors: any) =>
      allSelectedMentors.filter((mentorIndex: any) => mentorIndex !== index)
    )
  }
  function getStatusColorClass(status: any) {
    switch (status) {
      case "Pending":
        return "text-yellow-500"
      case "Not Booked":
        return "text-#ADADAD"
      case "not_booked":
        return "text-#ADADAD"
      case "Completed":
        return "text-green-500"
      case "":
        return "text-#ADADAD"
      case "Booked":
        return "text-yellow-500"
      default:
        return "text-red-500"
    }
  }
  const formatTimeTo12Hour = (time24: any) => {
    if (!time24) return undefined // Handle undefined or empty input

    const [hours, minutes] = time24.split(":").map(Number)
    const period = hours >= 12 ? "PM" : "AM"
    const adjustedHours = hours % 12 || 12
    const formattedMinutes = minutes !== undefined ? String(minutes).padStart(2, "0") : "00"

    return `${String(adjustedHours).padStart(2, "0")}:${formattedMinutes} ${period}`
  }
  const formatDateToDDMMYYYY = (dateString: any) => {
    if (!dateString) return undefined // Handle undefined or empty input

    const [year, month, day] = dateString.split("-")
    return `${day}/${month}/${year}`
  }
  function handleMentorSessionsTable(data: any) {
    const mentorSessionsList: any[] = data?.all_sessions?.map((item: any, i: number) => [
      item?.mentor_name,

      item?.start_timestamp
        ? format(new Date(item?.start_timestamp * 1000), "dd/MM/yyyy")
        : formatDateToDDMMYYYY(item?.date),
      item?.end_timestamp ? format(new Date(item?.start_timestamp * 1000), "hh:mm a") : formatTimeTo12Hour(item?.start),
      <LightTooltip
        title={item?.status === "Cancelled" ? "Review not available for cancelled session" : "Review session"}
        placement="bottom"
      >
        <span>
          <Button
            outlined
            className="w-fit text-new-accent"
            onClick={() => setReviewIndex(i)}
            disabled={item?.status === "Cancelled"}
          >
            Review
          </Button>
        </span>
      </LightTooltip>,

      item?.status || "-",
      item?.status === "Finished" ? item?.duration : "-",
      <LightTooltip
        title={item?.status === "Cancelled" ? "Feedback not available for cancelled session" : "Session Feedback"}
      >
        <span>
          <Button
            outlined
            className="ml-auto w-fit text-new-accent"
            onClick={() => setFeedbackIndex(i)}
            disabled={item?.status === "Cancelled"}
          >
            Feedback
          </Button>
        </span>
      </LightTooltip>,
    ])
    setTableData({
      header: ["Mentor Name", "Date", "Time", "Student Review", "Status", "Duration", "Mentor Feedback"],
      rows: mentorSessionsList || [],
    })
  }
  const handleCancelSession = (index: number) => {
    setCancelSessionIndex(index)
  }

  const handleRescheduleSession = (index: number) => {
    setRescheduleSessionIndex(index)
  }
  const [lastCompletedSessionId, setLastCompletedSessionId] = React.useState("")
  const [sessionId, setSessionId] = React.useState("")

  function handleNewMentorSessionsTable(data: any) {
    const n = data.length

    const mentorSessionsList: any[] = data?.map((item: any, i: number) => {
      const currentDate = new Date()
      const isDateRangeDefined = item?.startDate && item?.endDate
      const isPaymentDone = user?.payment_status !== "NOT_PAID"
      const isMilestoneSession = item?.session_id === "msid1"
      const sessionWithinRange =
        item?.session_status === "not_booked" &&
        item?.startDate &&
        item?.endDate &&
        new Date(item.startDate).getTime() - 15 * 24 * 60 * 60 * 1000 <= currentDate.getTime() && // Enable 15 days before startDate
        currentDate.getTime() <= new Date(item.endDate).getTime() // End date comparison
      const sessionTimeGreaterThanRange = item?.endDate && new Date() > new Date(item.endDate)
      const isBookNowEnabled =
        item?.session_status === "not_booked" &&
        item?.startDate &&
        item?.endDate &&
        new Date(item.startDate).getTime() - 15 * 24 * 60 * 60 * 1000 <= currentDate.getTime() && // Convert both sides to timestamps
        currentDate.getTime() <= new Date(item.endDate).getTime() &&
        (isBookingAvailable === true || lastCompletedSessionId === "")

      function formatFractionalTime(hour: string | number): string {
        const hour24 = parseFloat(hour.toString()) // Ensure it's a number
        const hours = Math.floor(hour24) // Extract the integer part (hours)
        const minutes = Math.round((hour24 % 1) * 60) // Convert the fractional part to minutes
        const formattedHours = hours.toString().padStart(2, "0") // Ensure two digits for hours
        const formattedMinutes = minutes.toString().padStart(2, "0") // Ensure two digits for minutes
        return `${formattedHours}:${formattedMinutes}` // Return time in "HH:MM" format
      }
      const sessionStartTime: string = item?.session_start_time || "" // Ensure it's a string
      const sessionEndTime: string = item?.session_end_time || ""
      const sessionStartTimeFormatted: string = formatFractionalTime(sessionStartTime)
      const sessionEndTimeFormatted: string = formatFractionalTime(sessionEndTime)

      // Create Date objects with the formatted session times
      const sessionDateTime: Date = new Date(`${item?.session_date}T${sessionStartTimeFormatted}:00`)
      const sessionEndDateTime: Date = new Date(`${item?.session_date}T${sessionEndTimeFormatted}:00`)

      const thirtyMinutesInMillis = 30 * 60 * 1000
      const joinStartTime = sessionDateTime.getTime() - thirtyMinutesInMillis
      const joinEndTime = sessionEndDateTime.getTime() + thirtyMinutesInMillis

      const isGreaterThan1Hr = currentDate.getTime() - sessionDateTime.getTime() > 60 * 60 * 1000
      const timeDifference: number = sessionDateTime.getTime() - currentDate.getTime()
      const timeDifferenceEndTime: number = currentDate.getTime() - sessionEndDateTime.getTime()
      const isGreaterThan24Hours: boolean = timeDifference > 24 * 60 * 60 * 1000
      const currTimeGreaterThanSessionStartTime =
        item?.startDate && new Date() <= new Date(new Date(item.startDate).getTime() - 15 * 24 * 60 * 60 * 1000)

      const isGreaterThan12Hours: boolean = timeDifference > (24 * 60 * 60 * 1000) / 2
      const isRescheduleEnabled: boolean = isGreaterThan12Hours && item?.session_status === "Booked"
      const isMarkMentorAbsentEnabled: boolean = item?.session_status === "Booked" && isGreaterThan1Hr
      const isJoinNowEnabled: boolean =
        currentDate.getTime() >= joinStartTime &&
        joinEndTime >= currentDate.getTime() &&
        (item?.session_status === "Booked" ||
          item?.status === "Booked" ||
          item?.session_status === "Completed" ||
          item?.status === "Completed")

      const isMentorReportVisible: boolean =
        item?.session_status === "Completed" && item?.student_review !== undefined && !isEmpty(item?.student_review)

      const hasMentorGivenFeedback: boolean = item?.mentor_feedback !== undefined && item?.mentor_feedback !== ""

      if (item?.session_status === "Completed") {
        setLastCompletedSessionId(item?.id)
      }
      function formatTime(hour24: any) {
        hour24 = parseFloat(hour24)

        const hours = Math.floor(hour24)
        const minutes = (hour24 % 1) * 60

        const period = hours < 12 ? "AM" : "PM"

        let hour12 = hours % 12
        hour12 = hour12 === 0 ? 12 : hour12

        return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`
      }
      return [
        0 + (i + 1),
        item?.name,
        item?.session_date && item?.session_start_time ? (
          <p
            className={
              item?.session_status != "not_booked" &&
              item?.session_status != "Completed" &&
              item?.session_status != "Booked"
                ? "text-red-500"
                : item?.status === "Completed"
                  ? ""
                  : "text-green-500"
            }
          >
            {formatDate(item?.session_date)} | {formatTime(item?.session_start_time)}
          </p>
        ) : item?.startDate?.charAt(0) !== "2" ? (
          "To be notified"
        ) : isBookNowEnabled ? (
          <p className="text-green-500">{formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}</p>
        ) : (
          <p>{formatDate(item?.startDate) + " - " + formatDate(item?.endDate)}</p>
        ),
        <LightTooltip
          title={
            item?.session_date
              ? item?.session_status
              : item?.session_status === "Booked"
                ? "You have already booked the session"
                : item?.session_status === "Completed"
                  ? "You have already completed the session"
                  : sessionTimeGreaterThanRange
                    ? "Session Expired"
                    : currTimeGreaterThanSessionStartTime
                      ? "Session Locked"
                      : !sessionWithinRange
                        ? "You have not booked session in the given date range"
                        : item?.session_status === "Completed"
                          ? "Your session has already been completed"
                          : !isBookingAvailable && lastCompletedSessionId !== ""
                            ? "You need to give rating for the last completed session first"
                            : !isPaymentDone
                              ? "You have not completed payment yet"
                              : item?.session_id === "msid1" && !isBookNowEnabled
                                ? "You have already booked the session"
                                : item?.session_id === "msid1"
                                  ? "Book Now"
                                  : !student?.mentor_id
                                    ? "You have not selected a mentor"
                                    : isBookNowEnabled
                                      ? "Book Now"
                                      : item?.session_status === "not_booked"
                                        ? isDateRangeDefined
                                          ? "You have not booked session in the given date range"
                                          : "Dates have not been confirmed by Admin yet!"
                                        : "You have already booked the session"
          }
          placement="bottom"
        >
          <span>
            <Button
              outlined
              className="border-primary-color-bp bg-bg-White ml-auto w-fit border border-solid"
              onClick={() => {
                setBookingIndex(i)
                setIndex(item?.id)
                setIsMilestoneSession(item?.session_id === "msid1")
                // setRealMentor(item?.mentor_name)
                setSessionId(item?.id)
              }}
              disabled={
                !isPaymentDone
                  ? true
                  : item?.session_id === "msid1" && isBookNowEnabled
                    ? false
                    : i === 0 && !isBookNowEnabled
                      ? true
                      : i === 0 && !isPaymentDone
                        ? true
                        : i === 0
                          ? false
                          : !isBookNowEnabled || !isPaymentDone || student?.mentor_id === ""
              }
            >
              Book Now
            </Button>
          </span>
        </LightTooltip>,
        <LightTooltip
          title={
            isJoinNowEnabled
              ? "Join Now"
              : item?.session_status === "not_booked"
                ? "Not Booked"
                : item?.session_status !== "Completed" && item?.session_status !== "Booked"
                  ? item?.session_status
                  : item?.session_status === "Completed"
                    ? "Your session has already been completed"
                    : "The link will be active 30 mins before the join time."
          }
          placement="bottom"
        >
          <span>
            <Button
              outlined
              className="border-primary-color-bp bg-bg-White ml-auto w-fit border border-solid"
              onClick={() => {
                window.location.href = item?.meet_link
              }}
              disabled={!isJoinNowEnabled}
            >
              Join Now
            </Button>
          </span>
        </LightTooltip>,
        item?.mentor_name === "not_booked" ? "Not Selected" : item?.mentor_name || "Not Selected",

        item?.session_status === "Completed" ? item?.duration + " mins" : "N/A",
        <p
          className={getStatusColorClass(
            item?.session_status !== "not_booked"
              ? item?.session_status
              : new Date(item?.endDate) < new Date()
                ? "Expired"
                : currTimeGreaterThanSessionStartTime
                  ? "Locked"
                  : item?.session_status === "not_booked"
                    ? "Not Booked"
                    : item?.session_status || "N/A"
          )}
        >
          {item?.session_status !== "not_booked"
            ? item?.session_status
            : new Date(item?.endDate) < new Date()
              ? "Expired"
              : currTimeGreaterThanSessionStartTime
                ? "Locked"
                : item?.session_status === "not_booked"
                  ? "Not Booked"
                  : item?.session_status || "N/A"}
        </p>,
        <LightTooltip
          title={
            item?.session_status !== "Completed" ? "You can only give review for completed sessions" : "Your review"
          }
        >
          <span>
            <Button
              outlined
              disabled={item?.session_status !== "Completed"}
              className="w-fit border-none bg-transparent underline hover:bg-transparent hover:text-new-accent"
              onClick={() => setReviewIndex(i)}
            >
              Click Here
            </Button>
          </span>
        </LightTooltip>,
        <LightTooltip
          title={
            isMentorReportVisible && hasMentorGivenFeedback
              ? "View Mentor Report"
              : hasMentorGivenFeedback
                ? "Mentor Report will be available only after you submit your feedback"
                : "Mentor has not provided any feedback yet."
          }
        >
          <span>
            <Button
              outlined
              disabled={!isMentorReportVisible || !hasMentorGivenFeedback}
              className="w-fit border-none bg-transparent underline hover:bg-transparent hover:text-new-accent"
              onClick={() => setFeedbackIndex(i)}
            >
              Click to View
            </Button>
          </span>
        </LightTooltip>,

        <div className="relative flex items-center">
          <button
            onClick={(event) => {
              setMenuOpen((isMenuOpen) => !isMenuOpen)
              setMenuActiveRow(i)
            }}
            className="relative text-white focus:outline-none"
          >
            <Menu />
          </button>
          {/* hovering=414141 normal=ADADAD */}
          {isMenuOpen && menuActiveRow === i && (
            <div
              className={`absolute ${
                i === n - 1 ? "bottom-5" : ""
              } right-6 z-[101] flex flex-col items-start gap-2 rounded-sm bg-white px-6 py-4 text-sm shadow-[0px_3px_7px_rgba(0,_0,_0,_0.1)]`}
            >
              <div
                onClick={() => {
                  setMenuOpen((isMenuOpen) => !isMenuOpen)
                  isRescheduleEnabled && handleCancelSession(i)
                }}
                style={{ pointerEvents: isRescheduleEnabled ? "auto" : "none" }}
              >
                <h4
                  className={` ${
                    isRescheduleEnabled ? "text-neutral-light hover:text-red-500" : " text-new-editor-dark-50"
                  } ${isRescheduleEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  Cancel Session
                </h4>
              </div>
              <div
                onClick={() => {
                  setMenuOpen((isMenuOpen) => !isMenuOpen)
                  setSessionId(item?.id)
                  setIsMilestoneSession(item?.session_id === "msid1")
                  isRescheduleEnabled && handleRescheduleSession(i)
                }}
                style={{ pointerEvents: isRescheduleEnabled ? "auto" : "none" }}
              >
                <h4
                  className={` ${
                    isRescheduleEnabled ? "!text-neutral-light hover:text-red-500" : " text-new-editor-dark-50"
                  } ${isRescheduleEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  Re-schedule Session
                </h4>
              </div>
              <div
                // onClick={
                //   isMarkMentorAbsentEnabled
                //     ? () => {
                //         setIsOpen(!isOpen)
                //         setMenuOpen(!isMenuOpen)
                //         setIndexToBeMarkedAbsent(item?.id)
                //       }
                //     : undefined
                // }

                onClick={() => {
                  setIsOpen(!isOpen)
                  setMenuOpen(!isMenuOpen)
                  setIndexToBeMarkedAbsent(item?.id)
                }}
              >
                <h4
                  className={` ${
                    isMarkMentorAbsentEnabled ? "!text-neutral-light hover:text-red-500" : " text-new-editor-dark-50"
                  } ${isMarkMentorAbsentEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                >
                  Mark Mentor absent
                </h4>
              </div>
              <div
                onClick={() => (window.location.href = "mailto:help@bosscoderacademy.com")}
                style={{ pointerEvents: "auto", cursor: "pointer" }}
              >
                <h4 className="text-neutral-light  hover:text-neutral-dark">More Help</h4>
              </div>
            </div>
          )}
        </div>,
      ]
    })
    setTableDataNew({
      header: [
        "No.",
        "Session Name",
        "Date",
        "Book Session",
        "Join Link",
        "Mentor Name",
        "Duration",
        "Status",
        "Student Feedback",
        "Mentor Feedback",
        "",
      ],
      rows: mentorSessionsList || [],
    })
  }
  async function fetchBookedSessions(forceFetch = false) {
    // Check if forceFetch is true or if data is not in localStorage
    const cachedSessions = localStorage.getItem("mentorSessions")

    if (!forceFetch && cachedSessions) {
      // If not forced to fetch, load from cache
      const sessions = JSON.parse(cachedSessions)
      setMentorSessions(sessions)
      setIsMentorSessionBooked(false)
      handleMentorSessionsTable(sessions)
      return
    }

    // If no cached data or forceFetch is true, make the API call
    const response = await getBookedSessionsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    // Store the fetched data in localStorage
    localStorage.setItem("mentorSessions", JSON.stringify(response?.data))
    localStorage.setItem("isBookedSessionCalled", "true")

    // Set the state with the new data
    setMentorSessions(response?.data)
    setIsMentorSessionBooked(false)
    handleMentorSessionsTable(response?.data)
  }
  // async function fetchBookedSessions() {
  //   const response = await getBookedSessionsUseCase.invoke(auth)

  //   if (!response?.success) {
  //     changeToastDetails(STR_FAILURE, response?.error)
  //     changeToastVisibility(true)
  //     return
  //   }
  //   setMentorSessions(response?.data)
  //   setIsMentorSessionBooked(false)
  //   handleMentorSessionsTable(response?.data)
  // }
  async function fetchAllMentorSessions() {
    setIsTableLoading(true)
    const response = await getAllMentorSessionUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setIsTableLoading(false)
      return
    }

    const groupedMap: any = []

    response?.data.forEach((session: any) => {
      const foundGroup = groupedMap.find((group: any) => group[0]?.session_id === session.session_id)

      if (foundGroup) {
        foundGroup.push(session)
      } else {
        groupedMap.push([session])
      }
    })

    setNewMentorSessionData(groupedMap)

    setMentorSessionData(response?.data)
    setIsTableLoading(false)
  }
  async function addStudentReview(rating: number, comment: string, session_id: string) {
    const response = await addStudentReviewUseCase.invoke(auth, rating, comment, session_id)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    return response
  }
  function isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }

  const handleGetMentorSessionDetails = async (data: any) => {
    const response = await getMentorSessionDetails.invoke(auth, {
      data: data,
    })
    setIsBookingAvailable(!isEmptyObject(response?.data?.student_review) || response?.data?.session_id === "msid1")
  }

  const bookMentorSession = async (date: any, start: any, end: any, session_id: any, mentor_id: string) => {
    setIsBookingInProgress(true)
    try {
      const response = await bookMentorSessionUseCase.invoke(auth, date, start, end, session_id, mentor_id)
      if (!response?.success) {
        changeToastDetails("failure", "This session is not available.")
        changeToastVisibility(true)
      } else {
        changeToastDetails("success", "Successfully booked the mentor session.")
        changeToastVisibility(true)
        setIsMentorSessionBooked(true)
        fetchBookedSessions(true)
        await fetchAllMentorSessions()
        if (!newMentorshipFlow) {
          window.location.reload()
        }
      }
    } catch (error) {
    } finally {
      setIsBookingInProgress(false)
    }
  }

  async function cancelMentorSession(session_id: string, type: string) {
    setCancellationInProgress(true)
    const response = await cancelMentorSessionUseCase.invoke(auth, session_id, cancelReason, type)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setCancellationInProgress(false)
      return
    }
    await fetchAllMentorSessions()
    changeToastDetails(STR_SUCCESS, "Previous mentor session has been cancelled.")
    fetchBookedSessions(true)
    setCancellationInProgress(false)
    changeToastVisibility(true)
    if (type != "reschedule") {
      // window.location.reload()
    }
  }

  function closeSelectedFeedbackDialog(type: string) {
    if (type === "review") setReviewIndex(-1)
    else if (type === "feedback") setFeedbackIndex(-1)
    else if (type === "cancel") setCancelSessionIndex(-1)
    else if (type === "reschedule") setRescheduleSessionIndex(-1)
    else if (type === "confirm") setConfirmSessionIndex(-1)
    else setFeedbackIndex(-1)
    setRating(0)
    setComment("")
  }
  function closeScheduleDialog() {
    setBookingIndex(-1)
  }
  async function handleSubmit(rating: any, comment: any) {
    if (Array.isArray(rating)) {
      for (let rate of rating) {
        if (rate.star === 0) {
          changeToastDetails(STR_FAILURE, "Please Fill all the mandatory fields!")
          changeToastVisibility(true)
          return // This will stop the execution of the function
        }
      }
    }

    setFeedbackLoading(true)
    const response = await addStudentReview(
      rating,
      comment,
      newMentorshipFlow ? reviewIndex : mentorSessions["all_sessions"]?.[reviewIndex]?.room_id
    )

    if (response?.success) {
      await fetchAllMentorSessions()
    }
    setFeedbackLoading(false)

    setIsMentorSessionBooked(true)
    if (!newMentorshipFlow) {
      window.location.reload()
    }
  }

  return {
    auth,
    realMentor,
    allotedMentor,
    milestoneSessionMentor,
    toast,
    changeToastDetails,
    mentor,
    isMentorAlloted,
    setIsMentorAlloted,
    mentorAvailability,
    mentorSessions,
    tableData,
    tableDataNew,
    isAllotmentStatusLoading,
    isMentorSessionBooked,
    reviewIndex,
    feedbackIndex,
    bookingIndex,
    cancelSessionIndex,
    rescheduleSessionIndex,
    confirmSessionIndex,
    mentorSessionData,
    getMentorDetailsByStudent,
    setMentorSessions,
    setIsMentorSessionBooked,
    handleMentorSessionsTable,
    rating,
    comment,
    student,
    limitReached,
    newMentorshipFlow,
    isMenuOpen,
    isMilestoneSession,
    menuActiveRow,
    milestoneSessionMentorId,
    bookingDetails,
    meetLink,
    handleMarkMentorAbsent,
    setMeetLink,
    getSessionJoiningLink,
    formatDate,
    getOrdinal,
    setBookingDetails,
    setRescheduleSessionIndex,
    setMilestoneSessionMentorId,
    setMenuOpen,
    setRating,
    setComment,
    setReviewIndex,
    setFeedbackIndex,
    setBookingIndex,
    setConfirmSessionIndex,
    setCancelSessionIndex,
    closeScheduleDialog,
    closeSelectedFeedbackDialog,
    handleSubmit,
    fetchMentorAvailability,
    addStudentReview,
    fetchBookedSessions,
    fetchAllMentorSessions,
    freezeData,
    bookMentorSession,
    cancelMentorSession,
    fetchAllMentors,
    fetchAllTempMentors,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    freezeMentorSlot,
    changeToastVisibility,
    handleNewMentorSessionsTable,
    handleGetMentorSessionDetails,
    isRescheduleEnabled,
    indexToBeMarkedAbsent,
    index,
    setIsRescheduleEnabled,
    setNewMentorshipFlow,
    isAlloting,
    BATCHES_TO_IGNORE_NMF,
    allMentors,
    mentorsList,
    getFreezeSlotDetails,
    allSelectedMentors,
    isSelectionStarted,
    setIsSelectionStarted,
    isPremiumPopupVisible,
    isOpen,
    lastCompletedSessionId,
    setIsOpen,
    setTableDataNew,
    startSelectionProcess,
    handleClosePopup,
    handleMentorSelection,
    handleMentorAllotment,
    getMentorNameFromId,
    mentorId,
    setMentorId,
    isAvailabilityLoading,
    markTakenElsewhere,
    setIsAvailabilityLoading,
    setMentor,
    isBookingInProgress,
    isTableLoading,
    mentorDetails,
    sessionId,
    setSessionId,
    setMsid,
    msid,
    setShowMentorPage,
    disableMentorCard,
    setDisableMentorCard,
    showMentorPage,
    BATCHES_TO_IGNORE_MENTOR_ALLOTMENT,
    cancellationInProgress,
    setCancellationInProgress,
    isBookingAvailable,
    isBookedSessionCalled,
    setIsBookedSessionCalled,
    newMentorSessionData,
    setLastCompletedSessionId,
    LightTooltip,
    setIsMilestoneSession,
    setRealMentor,
    setIndexToBeMarkedAbsent,
    markSessionCompletedPopup,
    setMarkSessionCompletedPopup,
    feedbackLoading,
    cancelReason,
    setCancelReason,
    tempMentorsList,
    fetchTempMentorAvailability,
    tempMentorAvailability,
    showTempMentor,
    getMentorJoinDuration,
    saveFeedback,
  }
}
